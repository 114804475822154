import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

store = new Vuex.Store(
  state:
    filterObjectsUrl: null
    filterHeatObjectsUrl: null
    mapViewModes: []

    itemsForViewModelObjects: []
    itemsForViewModelHeatMaps: []
    scaleForViewModelHeatMaps: {
      left: null
      right: null
      max: null
      min: null
    }
    totalCount: 0
    datasetTotalCount: null
    isLoading: true

    filter: {
      priceFrom: null
      priceTo: null
      areaFrom: null
      areaTo: null
      roiFrom: null
      roiTo: null

      variant: '__all__'

      objectType: '__all__'

      mapBoundsSW: null
      mapBoundsNE: null

      unscoped: null
    }

    comparedPropertyList: []
    
  mutations:
    initValues: (state, payload) ->
      state.filterObjectsUrl = payload.filter_objects_url if payload.filter_objects_url
      state.filterHeatObjectsUrl = payload.filter_heat_maps_url if payload.filter_heat_maps_url
      state.mapViewModes = payload.map_view_modes if payload.map_view_modes

      if payload.filter
        state.filter.roiTo = payload.filter.roi_to if payload.filter.roi_to
        state.filter.unscoped = payload.filter.unscoped if payload.filter.unscoped

      state.comparedPropertyList = payload.compared_property_list if payload.compared_property_list?

    changeItems: (state, payload) ->
      if payload.viewMode == 'objects'
        state.itemsForViewModelObjects = payload.items
      else if payload.viewMode == 'heat_maps'
        state.itemsForViewModelHeatMaps = payload.items

    changeScaleHeatmaps: (state, payload) ->
      if payload?
        state.scaleForViewModelHeatMaps.left = payload.left if payload.left?
        state.scaleForViewModelHeatMaps.right = payload.right if payload.right?

        state.scaleForViewModelHeatMaps.min = parseFloat(payload.min) if payload.min?
        state.scaleForViewModelHeatMaps.max = parseFloat(payload.max) if payload.max?
      else
        state.scaleForViewModelHeatMaps.left = null
        state.scaleForViewModelHeatMaps.right = null

        state.scaleForViewModelHeatMaps.min = null
        state.scaleForViewModelHeatMaps.max = null


    changeTotalCount: (state, payload) ->
      state.totalCount = payload

    changeDatasetTotalCount: (state, payload) ->
      state.datasetTotalCount = payload

    changeLoadingState: (state, payload) ->
      state.isLoading = payload

    changeFilter: (state, payload) ->
      state.filter[payload.key] = payload.value

  getters:
    itemsCount: (state) ->
      state.itemsForViewModelObjects.length

    filterOptions: (state) ->
      options = {}

      if state.filter.mapBoundsSW
        options.sw="#{state.filter.mapBoundsSW.lat};#{state.filter.mapBoundsSW.lng}"

      if state.filter.mapBoundsNE
        options.ne="#{state.filter.mapBoundsNE.lat};#{state.filter.mapBoundsNE.lng}"

      options.priceFrom = state.filter.priceFrom
      options.priceTo = state.filter.priceTo

      options.variants = state.filter.variant
      options.objectTypes = state.filter.objectType

      options.areaFrom = state.filter.areaFrom
      options.areaTo = state.filter.areaTo

      options.roiFrom = state.filter.roiFrom
      options.roiTo = state.filter.roiTo

      if state.filter.unscoped
        options.__unscoped = true

      options

    isMapViewModeObjects: (state) ->
      state.mapViewModes.includes('objects')

    isMapViewModeHeatMaps: (state) ->
      state.mapViewModes.includes('heat_maps')

    itemsForViewModeObjects: (state) ->
      state.itemsForViewModelObjects

    itemsForViewModeHeatMaps: (state) ->
      state.itemsForViewModelHeatMaps

    comparedPropertyList: (state) ->
      state.comparedPropertyList

  actions:
    filterItems: (context) ->
      if context.getters.isMapViewModeObjects
        context.commit('changeLoadingState', true)
        axios.get(context.state.filterObjectsUrl, params: context.getters.filterOptions)
          .then (response) ->
            context.commit('changeItems', {viewMode: response.data['view_mode'], items: response.data['items']})
            context.commit('changeTotalCount', response.data['total_count'])
            context.commit('changeDatasetTotalCount', response.data['dataset_total_count']) if response.data['dataset_total_count']?
            context.commit('changeLoadingState', false)
      if context.getters.isMapViewModeHeatMaps
        axios.get(context.state.filterHeatObjectsUrl, params: context.getters.filterOptions)
          .then (response) ->
            context.commit('changeItems', {viewMode: response.data['view_mode'], items: response.data['items']})
            context.commit('changeScaleHeatmaps', response.data['scale'])
)

export default store

