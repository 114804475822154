class RenderMapKml
  constructor: (map, kml) ->
    @__map = map
    @__kml = kml
    @__layout = null

  render: ->
    @__layout = new google.maps.KmlLayer({
      url: @__kml,
      map: @__map,
      preserveViewport: true
    });

export default RenderMapKml
