import Vue from 'vue'

class VueComponentInitializer
  @initStore: (options = {}) ->
    return unless options.store?

    document.querySelectorAll("[data-vue-store]").forEach (element) =>
      payload = JSON.parse(element.dataset['vueStore'])
      options.store.commit('initValues', payload)
      element.remove()

  # options:
  #   components: [{selector: '', component: VueObject}]
  #   store: Object
  @initComponents: (options) ->
    return unless options.components
    options.components.forEach (component) =>
      document.querySelectorAll(component.selector).forEach (element) =>
        data = Object.assign({}, element.dataset)
        Object.keys(data).forEach (key) =>
          v = data[key]
          if (v == 'true' || v == 'false')
            data[key] = v == 'true'
          else if (v.startsWith('[') && v.endsWith(']'))
            data[key] = JSON.parse(v)
          else if (v.startsWith('{') && v.endsWith('}'))
            data[key] = JSON.parse(v)

        delete data['vue-component']

        componentOptions = {
            el: element
            data: data
        }
        componentOptions.store = options.store if options.store?

        new Vue(Object.assign(componentOptions, component.component))


  @initAll: (options = {}) ->
    @initStore(options)
    @initComponents(options)

  @registerStore: (options)->
    document.addEventListener 'DOMContentLoaded', =>
      @initStore(options)

  @registerComponents: (options)->
    document.addEventListener 'DOMContentLoaded', =>
      @initComponents(options)

  @registerAll: (options) ->
    document.addEventListener 'DOMContentLoaded', =>
      @initAll(options)


export default VueComponentInitializer
