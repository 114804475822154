import 'rails-ujs'

import Vue from 'vue'
import ActionCableVue from 'actioncable-vue'
import VueLazyload from 'vue-lazyload'

import Map from '../shiva/components/map'
import MapFilter from '../shiva/components/map_filter'
import MapScaleHeatMaps from '../shiva/components/map_scale_heatmaps'
import MapObjectList from '../shiva/components/map_object_list'
import MapObjectCounter from '../shiva/components/map_object_counter'

import CalculationDetailsExpandTable from '../shiva/components/calculation_details/expand_table'

import VariantComparedObjects from '../shiva/components/varinat_compared_objects'

import ObjectsSoldOn from '../shiva/components/objects_sold_on'

import MapMarkers from '../shiva/components/map_markers'

import "../images/ac_club"
import '../images/realiste'
import '../images/shiva'

import image_nophoto from '../images/nophoto.png'
import image_logo from '../images/logo.svg'
import image_login_bg from '../images/login-bg.jpg'
import sprite from '../i/sprite.svg'
import '../fonts/index.css'

import store from '../shiva/store'

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: '/__cable'
});

window.shivaStore = store;

Vue.use(VueLazyload, {
  error: image_nophoto,
  lazyComponent: true
})

import VueComponentInitializer from "../common/vue_component_initializer"

components = [
  {selector: "[data-vue-component='shiva-map']", component: Map},
  {selector: "[data-vue-component='shiva-map-filter']", component: MapFilter},
  {selector: "[data-vue-component='shiva-map-scale-heatmaps']", component: MapScaleHeatMaps}
  {selector: "[data-vue-component='shiva-map-object-list']", component: MapObjectList},
  {selector: "[data-vue-component='shiva-map-object-counter']", component: MapObjectCounter}

  {selector: "[data-vue-component='shiva-map-markers']", component: MapMarkers}

  {selector: "[data-vue-component='variant-compared-objects']", component: VariantComparedObjects}

  {selector: "[data-vue-component='calculation-details-expand-table']", component: CalculationDetailsExpandTable}

  {selector: "[data-vue-component='objects-sold-list']", component: ObjectsSoldOn}
]

VueComponentInitializer.registerAll({components: components, store: store})
