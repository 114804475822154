






























export default {
   props:
     itemData:
       type: Object
       required: true

   computed:
     roiClass: ->
       {
         "c-primary": @itemData.roi_value >= 0
         "c-warning": @itemData.roi_value < 0
       }
}
