class RenderMapHeatMaps
  constructor: (map) ->
    @__map = map
    @__mapHeatMapsLayout = null

  render: (items) ->
    @__clearLayout()
    @__renderItems(items)

  __renderItems: (items) ->
    options = Object.assign({}, @__getLayoutDefaultOptions(), {data: @__generateLayoutData(items)})

    @__mapHeatMapsLayout = new google.maps.visualization.HeatmapLayer(options)

    console.log("[HeatmapLayer] Data items has #{options.data.length} elements")
    @__mapHeatMapsLayout.setMap(@__map);

  __generateLayoutData: (items) ->
    items.map (item) ->
      {
        location: new google.maps.LatLng(item.map_y, item.map_x),
        weight: parseFloat(item.heat_maps_weight)
      }

  __clearLayout: ->
    @__mapHeatMapsLayout.setMap(null) if @__mapHeatMapsLayout

  __getLayoutDefaultOptions: ->
    @__layoutOptions ||= {
      radius: 30,
      gradient: [
        'rgba(0, 255, 255, 0)',
        'rgba(0, 255, 255, 1)',
        'rgba(0, 191, 255, 1)',
        'rgba(0, 127, 255, 1)',
        'rgba(0, 63, 255, 1)',
        'rgba(0, 0, 255, 1)',
        'rgba(0, 0, 223, 1)',
        'rgba(0, 0, 191, 1)',
        'rgba(0, 0, 159, 1)',
        'rgba(0, 0, 127, 1)',
        'rgba(63, 0, 91, 1)',
        'rgba(127, 0, 63, 1)',
        'rgba(191, 0, 31, 1)',
        'rgba(255, 0, 0, 1)'
      ]
    }

export default RenderMapHeatMaps
