













































MIXIN_DATA = {
  data: ->
    {
      showFilterVariants: false
      availableVariants: []
      availableObjectTypes: []
      labels: {
        priceFrom: ""
        priceTo: ""
        squareFrom: ""
        squareTo: ""
      }
    }
}

export default {
  mixins: [MIXIN_DATA]

  computed:
    priceFrom:
      get: ->
        @$store.state.filter.priceFrom
      set: (value) ->
        @$store.commit('changeFilter', {key: 'priceFrom', value: value})

    priceTo:
      get: ->
        @$store.state.filter.priceTo
      set: (value) ->
        @$store.commit('changeFilter', {key: 'priceTo', value: value})

    areaFrom:
      get: ->
        @$store.state.filter.areaFrom
      set: (value) ->
        @$store.commit('changeFilter', {key: 'areaFrom', value: value})

    areaTo:
      get: ->
        @$store.state.filter.areaTo
      set: (value) ->
        @$store.commit('changeFilter', {key: 'areaTo', value: value})

    roiFrom:
      get: ->
        @$store.state.filter.roiFrom
      set: (value) ->
        @$store.commit('changeFilter', {key: 'roiFrom', value: value})

    roiTo:
      get: ->
        @$store.state.filter.roiTo
      set: (value) ->
        @$store.commit('changeFilter', {key: 'roiTo', value: value})

    variant:
      get: ->
        @$store.state.filter.variant
      set: (value) ->
        @$store.commit('changeFilter', {key: 'variant', value: value})

    objectType:
      get: ->
        @$store.state.filter.variant
      set: (value) ->
        @$store.commit('changeFilter', {key: 'objectType', value: value})


    isShowFilterVariants: ->
      @showFilterVariants && @availableVariants && @availableVariants.length > 0

    isShowFilterObjectTypes: ->
      @availableObjectTypes && @availableObjectTypes.length > 0

    isMapViewModeObjects: ->
      @$store.getters['isMapViewModeObjects']

    isMapViewModeHeatMaps: ->
      @$store.getters['isMapViewModeHeatMaps']

  methods:
    getMap: ->
      window.__map

    filterMapObjects: ->
      @$store.dispatch("filterItems")

    changedValue: ->
      @filterMapObjects()
}
