














import MapObjectListItem from './map_object_list_item'

export default {
  components: {MapObjectListItem}

  computed:
    items: ->
      @$store.getters.itemsForViewModeObjects

    isLoading: ->
      @$store.state.isLoading
}
