import noPhoto from '../../images/nophoto.png';
import mapPinRed from '../../i/map-pin-red.svg';
import mapPinGreen from '../../i/map-pin-green.svg';
import mapPinYellow from '../../i/map-pin-yellow.svg';
import mapPinDefault from '../../i/map-pin.svg';
import mapPinGrey from '../../i/map-pin-grey.svg';
import mapPinDarkGrey from '../../i/map-pin-dark-grey.svg';
import mapPinOrange from '../../i/map-pin-orange.svg';

import MarkerClusterer from '@google/markerclusterer'

class RenderMapObjectMarkers
  constructor: (map) ->
    @__map = map
    @__markers = []
    @__markerCluster = new MarkerClusterer(@__map, [], {imagePath: '/map_cluster/m'})
    @__infowindow = new google.maps.InfoWindow()

    google.maps.event.addListener(@__infowindow, 'closeclick', =>
      @__markers.forEach (marker) ->
        marker.setIcon(marker.originalIcon)
    )

  render: (items) ->
    @__clearMarkerCluster()
    @__renderList(items)

  __clearMarkerCluster: ->
    @__markerCluster.clearMarkers() if @__markerCluster
    @__markers = []

  __renderList: (items) ->
    items.forEach (item) =>
      icon = @__buildIcon(item)
      originalIcon = @__buildOriginalIcon(item)
      hoverIcon = @__buildHoverIcon(item)

      marker = new google.maps.Marker({
        map: @__map,
        position: new google.maps.LatLng(item.map_y, item.map_x),
        title: item.name,
        icon: icon,
        originalIcon: originalIcon,
        hoverIcon: hoverIcon
      });

      marker.__adId = item['id'];

      google.maps.event.addListener(marker, "mouseover", ->
        this.setIcon(this.hoverIcon)
      )

      google.maps.event.addListener(marker, "mouseout", ->
#        this.setIcon(this.originalIcon);
      );

      contentString = @__generateMarkerContent(item)

      google.maps.event.addListener(marker, 'click', (e) =>
        @__getInfoWindow().setContent(contentString)
        @__getInfoWindow().setOptions({maxWidth: 306, maxHeight: 290})
        @__getInfoWindow().open(@__map, marker)
        marker.setIcon(marker.hoverIcon)
      )
      @__markerCluster.addMarker(marker);
      @__markers.push(marker)



  __buildIcon: (item) ->
    {
      url: @__getPinMapping(item.icon_color)
      scaledSize: new google.maps.Size(40, 40)
      origin: new google.maps.Point(0,0)
      anchor: new google.maps.Point(20, 20)
    }

  __buildOriginalIcon: (item) ->
    {
      url: @__getPinMapping(item.icon_color)
      scaledSize: new google.maps.Size(40, 40)
      origin: new google.maps.Point(0,0)
      anchor: new google.maps.Point(20, 20)
    }

  __buildHoverIcon: (item) ->
    {
      url: @__getPinMapping('orange'),
      scaledSize: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0,0),
      anchor: new google.maps.Point(20, 20)
    }

  __generateMarkerContent: (item) ->
    roi_class = ""
    if item.roi_value >= 0
      roi_class = "c-primary"
    else
      roi_class = "c-warning"

    """
      <a class="flat-item" href="#{item.url}" target="_blank">
        <div class="flat-item__img">
          <img src="#{item.img}" alt="Image description" onerror="this.src='#{noPhoto}'">
        </div>
        <div class="flat-item__info">
          <div class="flat-item__info-head">
            <h2 class="flat-item__title">#{item.name}</h2>
            <span class="flat-item__roi-value #{roi_class}">#{item.roi}</span>
          </div>
          <p class="mb-0">Price</p>
          <p class="flat-item__price">
            <span class="flat-item__price">#{item.min_price}</span>
            <span class="flat-price-discount">#{item.discount}</span>
            <span class="price-old c-grey fw-normal text-sm">#{item.old_price}</span>
          <p>
        </div>
      </a>
    """

  __getInfoWindow: ->
    @__infowindow

  __getPinMappings: ->
    RenderMapObjectMarkers.getPinMappings()

  __getPinMapping: (iconColor) ->
    @__getPinMappings()[iconColor]

  @getPinMappings: ->
    @pinMapping ||= {
      'red': mapPinRed
      'green': mapPinGreen
      'yellow': mapPinYellow
      'default': mapPinDefault
      'orange': mapPinOrange
    }

export default RenderMapObjectMarkers
