












import VariantComparedObject from './varinat_compared_object'

MIXIN_DATA = {
  data: ->
    {
      comparedObjects: []
      showItemPropertiesCount: 2
    }
}

export default {
  mixins: [MIXIN_DATA]
  components: {VariantComparedObject}

  computed:
    comparedObjectsCount: ->
      @comparedObjects.length
  
}
