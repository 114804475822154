































import axios from 'axios'

MAX_COUNT_TO_DISPLAY = 200

MIXIN_DATA = {
  data: ->
    {
      headerCodes: []
      headers: []
      rows: null
      objectsCount: 0
      loadUrl: ''
      templateMetaInfoUrl: ''
      extractorType: ''
      variantFieldNames: ''
      variantName: ''
      loadingState: 'none'
    }
}

export default {
  mixins: [MIXIN_DATA]

  computed:
    state: ->
      if @loadingState == 'loading'
        'loading'
      else if @loadingState == 'loaded'
        'loaded'
      else if @isLoadManual
        'needLoadManual'
      else if @loadingState == 'none'
        'needLoad'
      else
        'needLoad'

    isLoadManual: ->
      @objectsCount > @maxCountToDisplay

    maxCountToDisplay: ->
      MAX_COUNT_TO_DISPLAY

  mounted: ->
    unless @isLoadManual
      @loadRows()

  methods:
    loadRows: ->
      params = {}
      params['variant_name'] = @variantName
      params['variant_field_names'] = @variantFieldNames.join(';')
      params['header_codes'] = @headerCodes.join(';')
      params['extractor_type'] = @extractorType
      @loadingState = 'loading'
      axios(@loadUrl, params: params).then (response) =>
        @loadingState = 'loaded'
        @rows = response.data.extractor_objects
        @headers = response.data.headers

    generateLinkToMeta: (id)->
      @templateMetaInfoUrl.replace(/__ID__/g, id)

    formatValue: (value, headerCode) ->
      if headerCode == 'square_price'
        Number((parseFloat(value)).toFixed(2))
      else
        value
}
