




import RenderMapObjectMarkers from '../libs/renderer_map_object_markers'
import RenderMapHeatMaps from '../libs/render_map_heat_maps'
import RenderMapKml from "../libs/render_map_kml"

MIXIN_DATA = {
  data: ->
    centerCoordinate: {
      lat: 40.70362789090823
      lng: -73.94653877421842
    }
    zoom: 11
    mapTypeControl: false
    zoomControl: true,
    streetViewControl: false,
    kmls: []
    maxZoom: null
    minZoom: null
}

mapInstance = null

export default {
  mixins: [MIXIN_DATA]

  mounted: ->
    @initMap()

  computed:
    mapViewMode: ->
      @$store.state.mapViewMode

    isMapViewModeObjects: ->
      @$store.getters.isMapViewModeObjects

    isMapViewModeHeatMaps: ->
      @$store.getters.isMapViewModeHeatMaps

    isMobileDevice: ->
      (typeof window.orientation != "undefined") || (navigator.userAgent.indexOf('IEMobile') != -1)
    
    deviceZoom: ->
      if @isMobileDevice then 9 else @zoom

    deviceMaxZoom: ->
      if @isMobileDevice then 10 else @maxZoom

    deviceMinZoom: ->
      if @isMobileDevice then 8 else @minZoom
    
    deviceZoomControl: ->
      if @isMobileDevice then false else @zoomControl

  methods:
    initMap: ->
      interval = null
      handler = =>
        if window.__mapJsLoaded
          mapInstance = new window.google.maps.Map(@$refs.map, @mapOptions());
          clearInterval(interval)

          @renderKmls(mapInstance)

          mapInstance.setOptions(@mapOptions())

          handlerFirstFilter = =>
            if mapInstance.getBounds()
              mapInstance.addListener("dragend", @filterMapObjects.bind(@));
              mapInstance.addListener("zoom_changed", @filterMapObjects.bind(@));
              @filterMapObjects()
              clearInterval(interval)
          interval = setInterval(handlerFirstFilter, 1)


      interval = setInterval(handler, 100)

    filterMapObjects: (event)->
      return unless @isZoomInBound()
      return unless mapInstance.getBounds()
      @$store.commit("changeFilter",
              {key: 'mapBoundsSW', value: {
                lat: mapInstance.getBounds().getSouthWest().lat()
                lng: mapInstance.getBounds().getSouthWest().lng()
              }})
      @$store.commit("changeFilter",
              {key: 'mapBoundsNE', value: {
                lat: mapInstance.getBounds().getNorthEast().lat()
                lng: mapInstance.getBounds().getNorthEast().lng()
              }})
      @$store.dispatch("filterItems")

    isZoomInBound: ->
      if(@deviceMinZoom && @deviceMinZoom != '' && mapInstance.getZoom() < parseInt(@deviceMinZoom))
        return false;

      if(@deviceMaxZoom && @deviceMaxZoom != '' && mapInstance.getZoom() > parseInt(@deviceMaxZoom))
        return false;

      return true;

    renderKmls: (manInstance) ->
      @kmls.forEach (kml) =>
        new RenderMapKml(mapInstance, kml).render()

    mapOptions: ->
      options = {
        mapTypeControl: @mapTypeControl,
        zoomControl: @deviceZoomControl,
        streetViewControl: @streetViewControl,
        center: new google.maps.LatLng(@centerCoordinate.lat, @centerCoordinate.lng),
        zoom: parseInt(@deviceZoom)
      }

      options.minZoom = parseInt(@deviceMinZoom) if @deviceMinZoom
      options.maxZoom = parseInt(@deviceMaxZoom) if @deviceMaxZoom

      options

    getRenderMakers: ->
      @__renderMakers ||= new RenderMapObjectMarkers(mapInstance)

    getRenderHeatMapsLayout: ->
      @__renderHeatMapsLayout ||= new RenderMapHeatMaps(mapInstance)

  watch:
    "$store.getters.itemsForViewModeObjects": (newItems, oldItems) ->
      @getRenderMakers().render(newItems) if @isMapViewModeObjects

    "$store.getters.itemsForViewModeHeatMaps": (newItems, oldItems) ->
      @getRenderHeatMapsLayout().render(newItems) if @isMapViewModeHeatMaps
}
