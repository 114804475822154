




export default {
  computed:
    itemsCount: ->
      @$store.getters.itemsCount

    totalCount: ->
      @$store.state.totalCount

    isShow: ->
      @itemsCount > 0

    datasetTotalCount: ->
      @$store.state.datasetTotalCount

    text: ->
      return '' unless @isShow
      r = "#{@itemsCount} of #{@totalCount}"
      r = "#{r} (total count 22804)" if @datasetTotalCount?
      r
}
