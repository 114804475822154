




import RenderMapHeatMaps from '../libs/render_map_heat_maps'
import MarkerClusterer from '@google/markerclusterer'

import axios from 'axios'

MIXIN_DATA = {
  data: ->
    zoom: 14
    centerMarker: {
      lat: null
      lng: null
      title: ""
      icon: ''
    }
    markers: []
    heatMapsEnabled: false
    heatMapsDataUrl: null
    heatMapsData: []
    heatMapsFilter: {}
}

mapInstance = null

infowindow = null

export default {
  mixins: [MIXIN_DATA]

  mounted: ->
    @initMap()

  computed:
    mapMarkers: ->
      @markers

  methods:
    initMap: ->
      interval = null
      handler = =>
        if window.__mapJsLoaded
          mapInstance = new window.google.maps.Map(@$refs.map, @mapOptions());
          mapInstance.addListener("dragend", @filterMapObjects.bind(@));
          mapInstance.addListener("zoom_changed", @filterMapObjects.bind(@));
          @renderClusterMarkers()
          @renderCenterMarker()
          clearInterval(interval)

          handlerFirstFilter = =>
            if mapInstance.getBounds()
              @filterMapObjects()
              clearInterval(interval)
          interval = setInterval(handlerFirstFilter, 1)

      interval = setInterval(handler, 100)

    mapOptions: ->
      {
        center: @getCenterLatLng(),
        zoom: parseInt(@zoom)
      }

    getCenterLatLng: ->
      @centerLatLng ||= new google.maps.LatLng(parseFloat(@centerMarker.lat), parseFloat(@centerMarker.lng))

    renderCenterMarker: ->
      m = new google.maps.Marker({
        position: @getCenterLatLng()
        map: mapInstance
        title: @centerMarker.title,
        icon: @getIconPath(@centerMarker.icon, 'http://maps.google.com/mapfiles/ms/icons/red-dot.png')
      })

      google.maps.event.addListener(m, 'click', @handlerClickOnMarker(@centerMarker, m).bind(@))

      m.setMap(mapInstance)


    renderClusterMarkers: ->
      @mapMarkers.forEach (marker) =>
        m = new google.maps.Marker({
          position: new google.maps.LatLng(marker.lat, marker.lng)
          map: mapInstance
          title: marker.title,
          icon: @getIconPath(marker.icon, 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
        })
        m.setMap(mapInstance)

        google.maps.event.addListener(m, 'click', @handlerClickOnMarker(marker, m).bind(@))

    handlerClickOnMarker: (markerInfo, marker) ->
      (e) =>
        @getInfoWindow().setContent(@generateMarkerInfoContent(markerInfo))
        @getInfoWindow().open(mapInstance, marker)

    generateMarkerInfoContent: (markerInfo) ->
      """
        <a class="flat-item" href="#{markerInfo.url}" target="_blank">
          <div class="flat-item__info">
            <div class="flat-item__info-head">
              <h2 class="flat-item__title">#{markerInfo.title}</h2>
            </div>
            <p class="mb-0">Price</p>
            <p class="flat-item__price">
              <span class="flat-item__price">#{markerInfo.price}</span>
            <p>
          </div>
        </a>
      """

    getInfoWindow: ->
      infowindow ||= new google.maps.InfoWindow()

    getIconPath: (icon, defaultValue) ->
      return defaultValue unless icon?

      if icon.startsWith('google-')
        "http://maps.google.com/mapfiles/ms/icons/#{icon.replace(/google-/, '')}.png"
      else
        icon

    filterMapObjects: ->
      @heatMapsFilter = {
        mapBoundsSW: {
          lat: mapInstance.getBounds().getSouthWest().lat()
          lng: mapInstance.getBounds().getSouthWest().lng()
        }
        mapBoundsNE: {
          lat: mapInstance.getBounds().getNorthEast().lat()
          lng: mapInstance.getBounds().getNorthEast().lng()
        }
      }

    getHeatMapsDataFilter: ->
      options = {}

      if @heatMapsFilter.mapBoundsSW?
        options.sw="#{@heatMapsFilter.mapBoundsSW.lat};#{@heatMapsFilter.mapBoundsSW.lng}"

      if @heatMapsFilter.mapBoundsNE?
        options.ne="#{@heatMapsFilter.mapBoundsNE.lat};#{@heatMapsFilter.mapBoundsNE.lng}"

      options

    loadHeatMapsData: ->
      return unless @heatMapsEnabled
      return unless @heatMapsDataUrl?
      axios.get(@heatMapsDataUrl, params: @getHeatMapsDataFilter())
        .then (response) =>
          @heatMapsData = response.data['items']

    getRenderHeatMapsLayout: ->
      @__renderHeatMapsLayout ||= new RenderMapHeatMaps(mapInstance)

  watch:
    heatMapsFilter: ->
      @loadHeatMapsData()

    heatMapsData: (newItems, oldItems) ->
      return unless @heatMapsEnabled
      @getRenderHeatMapsLayout().render(newItems)
}
