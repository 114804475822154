










MIXIN_DATA = {
  labels: {
    label: ""
  }
}

export default {
  mixins: [MIXIN_DATA]

  computed:
    scaleLeft: ->
      @$store.state.scaleForViewModelHeatMaps.left

    scaleRight: ->
      @$store.state.scaleForViewModelHeatMaps.right

    isMapViewModeHeatMaps: ->
      @$store.getters.isMapViewModeHeatMaps

    isComponentVisible: ->
      @isMapViewModeHeatMaps? && @scaleLeft? && @scaleRight?
}
