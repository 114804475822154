































import axios from 'axios'

MIXIN_DATA = {
  data: ->
    {
      items: []
      loaded: false
      loadUrl: ''
      dateFrom: null
      dateTo: null
    }
}

export default {
  mixins: [MIXIN_DATA]

  mounted: ->
    @loadItems()

  methods:
    loadItems: ->
      axios.get(@loadUrl, params: {date_from: @dateFrom, date_to: @dateTo}).then (response) =>
        @loaded = true
        @items = response.data.items
}
