


































import jquery from 'jquery'
require 'fotorama/fotorama'

export default {
  props: {
    item:
      type: Object
    showCount:
      type: Number
  }

  data: ->
    {
      showImage: false
      showHideProperties: false
    }

  computed:
    showItemProperties: ->
      @sliceProperties(0, @showCount - 1)

    hideItemProperties: ->
      @sliceProperties(@showCount, Object.keys(@item.properties).length - 1)

    showHidePropertiesText: ->
      if @showHideProperties
        "Hide details"
      else
        "Details"
        
  
  methods:
    imageListShowing: ->
      handler = =>
        $(@$refs.fotorama.$el).fotorama({
          arrows: false,
          height: 190
        })
        @showImage = true
      setTimeout(handler, 100)

    clickShowHideProperties: ->
      @showHideProperties = !@showHideProperties

    sliceProperties: (from, to) ->
      keys = Object.keys(@item.properties)
      return {} if keys.length <= from

      r = {}
      for key in keys[from..to]
        r[key] = @item.properties[key]

      r

}
